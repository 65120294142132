import React, {useState, useContext} from "react"
import {
    Pagination,
    Paper,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, IconButton, Icon,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import ProductsEditUniqueProductModal from "./ProductsEditUniqueProductModal"
import "./ProductsUniqueProductsCatalogue.scss"

const ProductsUniqueProductsList = ({
                                        handleFilterInputValueChange,
                                        filterInputValue,
                                        data,
                                        handlePageChange,
                                        pageNumber,
                                        count,
                                        getArticlesWithPicOnly,
                                        setGetArticlesWithPicOnly,
                                        handleDeleteArticle,
                                        triggerRefetch,
                                        setTriggerRefetch
                                    }) => {

    const categories = [
        {
            title: "Libellé",
            key: "libelle"
        },
        {
            title: "PU HT",
            key: "puht"
        },
        {
            title: "Matière",
            key: "finish"
        },
        {
            title: "Client",
            key: "customer"
        },
        {
            title: "Disponible à tous",
            key: "availableForAll"
        },
        {
            title: "Photo",
            key: "picture"
        },
        {
            title: "Actions",
            key: "actions"
        }
    ]

    const [articleCurrentlyEdited, setArticleCurrentlyEdited] = useState(null)

    const handleSetArticleInEdition = (articleId) => {
        if (articleId) {
            setArticleCurrentlyEdited(articleId)
        } else setArticleCurrentlyEdited(null)
    }

    return (
        <div className={"products-unique-all-products-wrapper"}>
            <>
                <div className={"products-unique-all-products-wrapper-header"}>
                    <div className={"products-unique-all-products-wrapper-header-title"}>Liste des articles uniques présents en base
                    </div>
                    <div style={{flex:1}}/>
                    <FormControlLabel control={<Checkbox onChange={() => setGetArticlesWithPicOnly(!getArticlesWithPicOnly)}/>} label="Avec photo uniquement"/>
                    <TextField className={"products-unique-all-products-wrapper-header-filter-input"} size="small"
                               variant="outlined" onChange={handleFilterInputValueChange}
                               label={"Rechercher"}
                               value={filterInputValue}/>
                </div>
                <div className={"products-unique-all-products-wrapper-content"}>
                    <TableContainer component={Paper} className={"products-unique-all-products-wrapper-content-table"}>
                        <Table size={"small"} stickyHeader={true} style={{border:"1px solid #EFEFEF"}}>
                            <TableHead>
                                <TableRow>
                                    {categories.map((category) => {
                                        return (
                                            <TableCell style={{backgroundColor:"#FAFAFA"}} key={category.key}>{category.title}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((article) => (
                                    <TableRow
                                        key={article.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell style={{width: "300px"}}>{article.article_unique_libelle}</TableCell>
                                        <TableCell style={{width: "150px"}}>{article.prixHT} €</TableCell>
                                        <TableCell style={{width: "150px"}}>{article.matiere_libelle ? article.matiere_libelle : "Non spécifié"}</TableCell>
                                        <TableCell style={{width: "150px"}}>{article.client_nom}</TableCell>
                                        <TableCell style={{width: "150px"}}>{(!article.disponiblePourTous || article.disponiblePourTous === "0") ? "Non" : "Oui"}</TableCell>
                                        <TableCell style={{width: "150px"}}>{article.picture_id ? "Oui" : "Non"}</TableCell>
                                        <TableCell style={{width: "150px"}}>
                                            <IconButton
                                                onClick={() => handleSetArticleInEdition(article)}
                                                style={{}}>
                                                <Icon color={"#555555"}>{"edit"}</Icon>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleDeleteArticle(article.id)}
                                                style={{}}>
                                                <Icon color={"#555555"}>{"delete"}</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={"products-unique-all-products-wrapper-content-pagination"}>
                        <Pagination count={Math.floor(count / 10)} onChange={handlePageChange} page={pageNumber}/>
                    </div>
                </div>
            </>
            {articleCurrentlyEdited && <ProductsEditUniqueProductModal article={articleCurrentlyEdited} handleClose={handleSetArticleInEdition} setTriggerRefetch={setTriggerRefetch} triggerRefetch={triggerRefetch}/>}
        </div>
    )
}

export default ProductsUniqueProductsList
