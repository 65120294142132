import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Checkbox, DialogContent, FormControlLabel, TextField, Box, InputLabel, FormControl} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import {getAllFinish, createArticle} from "../api/ProductsAPI";
import {getThicknessRules, getLengthRules, getDevelopedRules} from "../../pricingRules/api/PricingRulesAPI";
import MenuItem from '@mui/material/MenuItem';
import {removeDuplicateItems} from "../../../shared/utils/Utils";
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import toast from 'react-hot-toast';

const ProductsAddArticleModal = ({article, mode, handleClose, triggerRefetch}) => {

    const [libelle, setLibelle] = useState(article.article_libelle)
    const [finishIds, setFinishIds] = useState(article.finish)
    const [lengthRule, setLengthRule] = useState(article.loi_longueur_libelle)
    const [thicknessRule, setThicknessRule] = useState(article.loi_epaisseur_libelle)
    const [developedRule, setDevelopedRule] = useState(article.loi_developpe_libelle)

    const [finishList, setFinishList] = useState(null)
    const [allLengthRules, setAllLengthRules] = useState(null)
    const [allThicknessRules, setAllThicknessRules] = useState(null)
    const [allDevelopedRules, setAllDevelopedRules] = useState(null)

    useEffect(() => {
        handleGetAllFinish()
        handleGetAllLengthRules()
        handleGetAllThicknessRules()
        handleGetAllDevelopedRules()
    }, [])

    const handleGetAllFinish = async () => {
        const allFinish = manageFinishData(await getAllFinish())
        setFinishList(allFinish)
    }

    const handleGetAllLengthRules = async () => {
        const allLengthRules = await getLengthRules()
        setAllLengthRules(allLengthRules.loi_longueur)
    }

    const handleGetAllThicknessRules = async () => {
        const allThicknessRules = await getThicknessRules()
        setAllThicknessRules(allThicknessRules.loi_epaisseur)
    }

    const handleGetAllDevelopedRules = async () => {
        const allDevelopedRules = await getDevelopedRules()
        setAllDevelopedRules(allDevelopedRules.loideveloppe)
    }

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }

    const handleSetFinish = (event) => {
        const {
            target: {value},
        } = event;
        setFinishIds(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSetLengthRule = (e) => {
        setLengthRule(e.target.value)
    }

    const handleSetThicknessRule = (e) => {
        setThicknessRule(e.target.value)
    }

    const handleSetDevelopedRule = (e) => {
        setDevelopedRule(e.target.value)
    }

    const manageFinishData = (data) => {
        const finalFinish = removeDuplicateItems(data, "matiere_id").map((finish) => {
            return (
                {
                    "matiere_libelle": finish.matiere_libelle,
                    "matiere_id": finish.matiere_id,
                    "petit_developpe": [],
                    "grand_developpe": [],
                    "surface": []
                }
            )
        })
        return finalFinish.sort((a, b) => a.matiere_libelle.localeCompare(b.matiere_libelle))
    }

    const handleCreateEditArticle = async () => {
        if (mode === "create") {
        }
        const payload = {
            libelle: libelle,
            matiereIds: finishIds,
            loiDeveloppeId: developedRule,
            loiEpaisseurId: thicknessRule,
            loiLongueurId: lengthRule
        }
        try {
            await createArticle(payload)
            triggerRefetch()
            toast.success("L'article a été ajouté avec succès")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de l'article")
        }
    }

    return (
        <Dialog open={article} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Création d'un article</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <TextField
                        style={{marginBottom: "12px"}}
                        value={libelle}
                        onChange={(e) => handleSetLibelle(e.target.value)}
                        variant="outlined"
                        label={"Libellé"}
                    />
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisissez la loi de longueur applicable</InputLabel>
                        <Select
                            style={{marginBottom: "12px"}}
                            label={'Choisissez la loi de longueur applicable'}
                            className={""}
                            onChange={handleSetLengthRule}
                        >
                            {allLengthRules && allLengthRules.map((rule) => {
                                return (
                                    <MenuItem key={rule.id} value={rule.id}
                                    >{rule.libelle}</MenuItem>
                                )
                            })}
                        </Select>
                    </ FormControl>
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisissez la loi d'épaisseur applicable</InputLabel>
                        <Select
                            style={{marginBottom: "12px"}}
                            label={"Choisissez la loi d'épaisseur applicable"}
                            className={""}
                            onChange={handleSetThicknessRule}
                        >
                            {allThicknessRules && allThicknessRules.map((rule) => {
                                return (
                                    <MenuItem key={rule.id} value={rule.id}
                                    >{rule.loi_epaisseur_libelle}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisissez la loi de développé applicable</InputLabel>
                        <Select
                            style={{marginBottom: "12px"}}
                            label={"Choisissez la loi de développé applicable"}
                            className={""}
                            onChange={handleSetDevelopedRule}
                        >
                            {allDevelopedRules && allDevelopedRules.map((rule) => {
                                return (
                                    <MenuItem key={rule.id} value={rule.id}
                                    >{rule.libelle}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisissez les finitions applicables</InputLabel>
                        <Select
                            label={'Choisissez les finitions applicables'}
                            multiple
                            value={finishIds}
                            onChange={handleSetFinish}
                            MenuProps={{
                                style: {
                                    maxHeight: "200px",
                                },
                            }}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => {
                                        return (
                                            <Chip key={value} label={finishList.find((finish) => finish.matiere_id === value).matiere_libelle}/>
                                        )
                                    })
                                    }
                                </Box>
                            )}
                        >
                            {
                                finishList && finishList.map((finish) => {
                                        return (
                                            <MenuItem key={finish.matiere_id} value={finish.matiere_id}>{finish.matiere_libelle}</MenuItem>
                                        )
                                    }
                                )
                            }
                        </Select>
                    </ FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(null)}>Annuler</Button>
                <Button
                    onClick={() => handleCreateEditArticle()}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductsAddArticleModal