import React, {useState, useEffect} from "react"
import {withRouter} from "react-router-dom";
import {
    getOperationDetail,
    deleteEcriture,
    downloadOperationPDF,
    changeOperationStatus,
    deleteOperation,
    deleteUniqueArticle,
    deleteStandardArticle,
    patchEcriture,
    patchArticleCommande,
    patchArticleUniqueCommande, reorderOperationElements
} from "../api/OperationsAPI";
import {groupArticles, numberWithCommas, numberWithSpaces} from "../../../shared/utils/Utils";
import {
    Button,
    Checkbox,
    Chip,
    Icon,
    IconButton,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    FormControl,
    InputLabel, Stepper, Step, StepLabel, Tooltip, ButtonGroup, Menu, Divider
} from "@mui/material";
import dayjs from "dayjs";
import Loader from "../../../shared/loader/Loader";
import OperationDetailsModal from "../OperationDetailsModal";
import "./OperationDetails.scss"
import OperationDetailsAddUniqueArticle from "./OperationDetailsAddUniqueArticle";
import OperationDetailsAddArticle from "./OperationDetailsAddArticle";
import OperationDetailsAddEcriture from "./OperationDetailsAddEcriture";
import OperationDetailsCreateAndAddUniqueArticle from "./OperationDetailsCreateAndAddUniqueArticle";
import OperationDetailsEditModal from "./OperationDetailsEditModal";
import toast from "react-hot-toast";
import {
    AddCircle,
    ChevronLeft,
    ChevronLeftOutlined,
    ChevronRight, Delete, Edit, Email,
    Engineering,
    LocalShipping, PendingActions, PictureAsPdf, Plagiarism,
    RequestQuote, SquareFoot,
    Sync
} from "@mui/icons-material";
import OperationDetailsCreateSerieModal from "./OperationDetailsCreateSerieModal"

const OperationDetails = (props) => {

    const [data, setData] = useState(null)
    const [dragAndDrop, setDragAndDrop] = useState({originalIndex: null, desiredNewIndex: null})
    const [selectedElement, setSelectedElement] = useState(null)
    const [isAddUniqueArticleModalOpen, setIsAddUniqueArticleModalOpen] = useState(false)
    const [isAddEcritureModalOpen, setIsAddEcritureModalOpen] = useState(false)
    const [isAddArticleModalOpen, setIsAddArticleModalOpen] = useState(false)
    const [isCreateSerieModalOpen, setIsCreateSerieModalOpen] = useState(false)
    const [isCreateAndAddUniqueArticleModalOpen, setIsCreateAndAddUniqueArticleModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [triggerRefresh, setTriggerRefresh] = useState(false)

    const operationId = props.match.params.operationId

    useEffect(() => {
        handleGetOperationDetail()
    }, [triggerRefresh])

    useEffect(() => {
        if(selectedElement){
            if(selectedElement.similaire){
                handleOpenCloseAddArticleModal()
            }
        }
        if(selectedElement){
            if(selectedElement.serie){
                handleOpenCloseCreateSerieModal()
            }
        }
    }, [selectedElement])

    const handleGetOperationDetail = async () => {
        try {
            let operationDetails = await getOperationDetail(operationId)
            const groupedOperationData = groupArticles(operationDetails)
            console.log(groupedOperationData)
            operationDetails.sortedElements = groupedOperationData.articlesToDisplay
            if(operationDetails.facture) {
                console.log(operationDetails.facture);
                operationDetails.facture.completeNo = operationDetails.facture.date.split('-').slice(0,2).join("") + "0" + operationDetails.facture.numero
            }
            setData(operationDetails)
        } catch (err) {
            console.log(err)
        }
    }

    function getActiveStep() {
        if(!data) return 0;
        if(data.operation.statut === "devis") {
            return 0
        } else if(data.operation.statut === "commande" || data.operation.statut === "archive") {
            return 1;
        } else if(data.operation.statut === "livraison") {
            return 2;
        } else if(data.operation.statut === "facture") {
            if(data.facture?.datePaiement === null) {
                return 3
            } else {
                return 4;
            }
        }
        return 5;
    }

    function renderGoToPreviousStep() {
        if(data.operation.statut === "archive") return <Button size="small" color={"secondary"} variant={"text"} startIcon={<ChevronLeft/>} onClick={() => handleChangeOperationStatus("devis")}>Repasser en devis</Button>
        let step = getActiveStep();
        if(step === 0) return <div/>;
        if(step === 1) return <Button variant={"text"} size="small" color={"secondary"} startIcon={<ChevronLeft/>} endIcon={<PendingActions/>} onClick={() => handleChangeOperationStatus("devis")} >Repasser en devis</Button>;
        if(step === 2) return <Button variant={"text"} size="small" color={"secondary"} startIcon={<ChevronLeft/>}  onClick={() => handleChangeOperationStatus("commande")}>Repasser en commande</Button>;
        return <div/>;

    }
    function renderGoToNextStep() {
        if(data.operation.statut === "archive") return <div/>;
        let step = getActiveStep();
        if(step === 0) return <Button variant={"text"} size="small" endIcon={<ChevronRight/>} startIcon={<Sync/>}  onClick={() => handleChangeOperationStatus("commande")}>Passer en commande</Button>;
        if(step === 1) return <Button variant={"text"} size="small" endIcon={<ChevronRight/>} startIcon={<LocalShipping/>} onClick={() => handleChangeOperationStatus("livraison")}>Passer en livraison</Button>;
        return <div/>;

    }

    const handleClickOnSimilaire = (element) => {
        // setSelectedElement({similaire:{element}})
    }

    const handleClickOnSerie = (element) => {
        setSelectedElement({serie:{element}})
    }

    const handleHideShowElementDetails = (element) => {
        const el = document.getElementById(`operations-details-table-wrapper-table-element-detail-${element.id}`)
        el.style.display === "none" ? el.style.display = "table-cell" : el.style.display = "none"
    }

    const categories = [
        // {
        //     title: "",
        //     key: "tick"
        // },
        {
            title: "#",
            key: "order"
        },
        {
            title: "Designation",
            key: "label"
        },
        {
            title: "Actions",
            key: "actions"
        },
        {
            title: "PU HT",
            key: "puht"
        },
        {
            title: "Qté",
            key: "quantity"
        },
        {
            title: "Total",
            key: "deliveryDate"
        },
        {
            title: "Suppr.",
            key: "delete"
        },
    ]

    const actionsSelectValues = [
        {
            title: "Supprimer les éléments",
            key: "delete"
        },
        // {
        //     title: "Changer le décor",
        //     key: "changeFinish"
        // },
        // {
        //     title: "Echanger la place des éléments dans la liste",
        //     key: "switch"
        // },
        // {
        //     title: "Remonter l'élément du bas sous celui du haut",
        //     key: "goUp"
        // },
        // {
        //     title: "Placer l'élément en bas de la liste",
        //     key: "goDown"
        // }
    ]


    const handleRedirectToClientDetails = (clientId) => {
        props.history.push(`/clients/list/${clientId}`)
    }

    const renderElementActionBar = (element) => {
        if (element.type === "note") {
            return (
                <div>
                    <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Note"/>
                </div>
            )
        }
        if (element.type === "pourcentage") {
            return (
                <div>
                    <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Pourcentage"/>
                </div>
            )
        }
        if (element.type === "standard") {
            return (
                <div>
                    <IconButton
                        size={"small"}
                        onClick={() => handleHideShowElementDetails(element)}
                        style={{}}>
                        <Icon color={"primary"}>{"keyboard_arrow_down_icon"}</Icon>
                    </IconButton>
                    <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Art. standard"/>
                    <Chip className={"operations-details-table-wrapper-table-chip"} onClick={() => handleClickOnSerie(element)} size={"small"} label="Série" variant="outlined"/>
                    <Chip className={"operations-details-table-wrapper-table-chip"} disabled onClick={() => handleClickOnSimilaire(element)} size={"small"} label="Similaire"
                          variant="outlined"/>
                </div>
            )
        }
        if (element.type === "unique") {
            return (
                <div>
                    <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Art. unique"/>
                </div>
            )
        }
        if (element.type === "ecriture") {
            return (
                <div>
                    <Chip className={"operations-details-table-wrapper-table-chip"} size={"small"} label="Ecriture"/>
                </div>
            )
        }
    }

    const handleDeleteElement = async (element, dontRefresh) => {
        if (element.type === "note" || element.type === "ecriture" || element.type === "pourcentage") {
            try {
                await deleteEcriture(element.id)
                !dontRefresh && toast.success("L'écriture a été supprimée avec succès")
                !dontRefresh && handleSetTriggerRefresh()
            } catch (err) {
                console.log(err)
                !dontRefresh && toast.error("Erreur durant la suppression de l'écriture")
            }
        }
        if (element.type === "unique") {
            try {
                await deleteUniqueArticle(element.id)
                !dontRefresh && toast.success("L'article unique a été supprimé avec succès")
                !dontRefresh && handleSetTriggerRefresh()
            } catch (err) {
                console.log(err)
                !dontRefresh && toast.error("Erreur durant la suppression de l'article unique")
            }
        }
        if (element.type === "standard") {
            try {
                await deleteStandardArticle(element.id)
                !dontRefresh && toast.success("L'article standard a été supprimé avec succès")
                !dontRefresh && handleSetTriggerRefresh()
            } catch (err) {
                console.log(err)
                !dontRefresh && toast.error("Erreur durant la suppression de l'article standard")
            }
        }
    }

    const handlePatchElement = async (element) => {
        if (element.type === "note" || element.type === "ecriture" || element.type === "pourcentage") {
            try {
                await patchEcriture(element, element.id)
                toast.success("L'écriture a été modifiée avec succès")
                handleSetTriggerRefresh()
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification de l'écriture")
            }
        }
        if (element.type === "unique") {
            try {
                await patchArticleUniqueCommande(element, element.id)
                toast.success("L'article unique a été modifié avec succès")
                handleSetTriggerRefresh()
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification de l'article unique")
            }
        }
        if (element.type === "standard") {
            try {
                await patchArticleCommande(element, element.id)
                toast.success("L'article standard a été modifié avec succès")
                handleSetTriggerRefresh()
            } catch (err) {
                console.log(err)
                toast.error("Erreur durant la modification de l'article standard")
            }
        }
    }

    const handleOpenCloseAddUniqueArticleModal = () => {
        setIsAddUniqueArticleModalOpen(!isAddUniqueArticleModalOpen)
    }

    const handleOpenCloseAddArticleModal = () => {
        if(isAddArticleModalOpen){
            setIsAddArticleModalOpen(false)
            setSelectedElement(null)
        } else {
            setIsAddArticleModalOpen(true)
        }
    }

    const handleOpenCloseCreateSerieModal = () => {
        if(isCreateSerieModalOpen){
            setIsCreateSerieModalOpen(false)
            setSelectedElement(null)
        } else {
            setIsCreateSerieModalOpen(true)
        }
    }

    const handleOpenCloseAddEcritureModal = () => {
        setIsAddEcritureModalOpen(!isAddEcritureModalOpen)
    }

    const handleOpenCloseCreateAndAddUniqueArticleModal = () => {
        setIsCreateAndAddUniqueArticleModalOpen(!isCreateAndAddUniqueArticleModalOpen)
    }

    const handleOpenCloseEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen)
    }


    const handleSetTriggerRefresh = () => {
        setTriggerRefresh(!triggerRefresh)
    }

    const handleClickOnEditDocument = (documentType) => {
        downloadOperationPDF(data, documentType)
    }

    const handleChangeOperationStatus = async (status) => {
        await changeOperationStatus({statut: status}, data.operation.id)
        handleSetTriggerRefresh()
    }

    const handleDeleteOperation = async () => {
        if(!window.confirm("Êtes-vous sûr(e) de vouloir supprimer cette opération ? Ceci est irréversible.")) return;   //Dont forget this
        try {
            await deleteOperation(operationId)
            toast.success("L'opération a été supprimée avec succès")
            props.history.goBack()
        } catch (err) {
            toast.error("Erreur durant la suppression de l'opération")
            console.log(err)
        }
    }

    const renderOperationDocuments = () => {
        return (
            <div>
                <div className={'operations-details-right-part-category-wrapper-button'} onClick={() => handleClickOnEditDocument("quote")}>

                        <span>
                              Devis
                        </span>
                    <div style={{flex: 1}}/>
                    <PictureAsPdf/>
                </div>
                <div className={'operations-details-right-part-category-wrapper-button'} onClick={() => handleClickOnEditDocument("proforma")}>
                        <span>
                              Proforma
                        </span>
                    <div style={{flex: 1}}/>
                    <PictureAsPdf/>
                </div>
                <div className={'operations-details-right-part-category-wrapper-button'} onClick={() => handleClickOnEditDocument("deliveryReceipt")}>
                        <span>
                              Bon de livraison
                        </span>
                    <div style={{flex: 1}}/>
                    <PictureAsPdf/>
                </div>
            </div>
        )
    }

    async function reorder(event) {
        if(dragAndDrop.originalIndex === dragAndDrop.desiredNewIndex) {
            //We do nothing
            setDragAndDrop({originalIndex: null, desiredNewIndex: null});
            return;

        }
        let result = data.sortedElements.slice() // We know that sortedElements are... Sorted
        let source = result[dragAndDrop.originalIndex];
        result.splice(dragAndDrop.originalIndex, 1);  //We take off the element we want to replace
        result.splice(dragAndDrop.desiredNewIndex, 0, source);   //We now place it back in the place we want
        result.map((e, k) => {
            e.ordre = k;
            return e;
        })
        //Now we will store the updated results.
        console.log(result);
        await reorderOperationElements(data.operation.id, result.map(e => {
            let type = "E"; //Otherwise it's an ecriture (for note, pourcentage, etc)
            if(e.type === "standard") {
                type = "AC";
            } else if(e.type === "unique") {
                type = "AUC";
            }
            return {className: type, order: e.ordre, id: e.id};


        }))
        await handleGetOperationDetail();
        setDragAndDrop({originalIndex: null, desiredNewIndex: null});

        //We reorder the stuff.

    }

    const renderOperationActions = () => {
        if (data.operation.statut === "facture") {
            return
        }
        return (
            <div>
                <div className={'operations-details-right-part-category-wrapper-button disabled'}>
                        <span>
                             Envoyer un mail
                        </span>
                    <div style={{flex: 1}}/>
                    <Email/>
                </div>
                <div className={'operations-details-right-part-category-wrapper-button'} onClick={() => handleOpenCloseEditModal()}>
                        <span>
                              Modifier l'opération
                        </span>
                    <div style={{flex: 1}}/>
                    <Edit/>
                </div>
                <div className={'operations-details-right-part-category-wrapper-button'} style={{backgroundColor: "#F2DEDE"}} onClick={() => handleDeleteOperation()}>
                        <span>
                             Supprimer l'opération
                        </span>
                    <div style={{flex: 1}}/>
                    <Delete/>
                </div>

            </div>
        )
    }

    function renderStepper() {
        return <div style={{marginTop: 20, marginBottom: 20}}>
            <Stepper activeStep={getActiveStep()} alternativeLabel>
                <Step color={"blue"}>
                    <StepLabel>Nouveau devis</StepLabel>
                </Step>
                <Step>
                    <StepLabel>{! data.facture && data.operation.statut === "archive" ? "Archive" : "Commande en production"}</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Commande en livraison</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Commande facturée
                        {
                            data.facture && <small><br/>facture n°{data.facture.completeNo}</small>
                        }
                    </StepLabel>

                </Step>
                <Step>
                    <StepLabel>réglé
                        {
                            data.facture?.datePaiement && <small><br/>payé le {data.facture.datePaiement.split('-').reverse().join('/')}</small>
                        }
                    </StepLabel>
                </Step>
            </Stepper>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: 20}}>
                { renderGoToPreviousStep()}
                { renderGoToNextStep()}
            </div>
        </div>
    }

    const [anchorElAEM, setAnchorElAEM] = React.useState(null);
    const openAEM = Boolean(anchorElAEM);
    const handleClickAEM = (event) => {
        setAnchorElAEM(event.currentTarget);
    };
    const handleCloseAEM = () => {
        setAnchorElAEM(null);
    };


    function renderAddElements() {
        return <div className={"add-elements-container"}>
            <Button size={"small"} variant={"outlined"} onClick={handleClickAEM} startIcon={<AddCircle/>}>Ajouter une nouvelle écriture</Button>
            <Menu
                id="add-element-menu"
                anchorEl={anchorElAEM}
                open={openAEM}
                onClose={handleCloseAEM}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled={true}>Articles classique (cornière...)</MenuItem>
                <MenuItem onClick={_ => {handleCloseAEM(); handleOpenCloseAddArticleModal();}}><SquareFoot/> Utiliser un Article classique existant</MenuItem>
                <Divider />
                <MenuItem disabled={true}>Articles uniques</MenuItem>
                <MenuItem onClick={_ => {handleCloseAEM(); handleOpenCloseAddUniqueArticleModal();}}><Plagiarism/> Utiliser un Article unique existant</MenuItem>
                <MenuItem onClick={_ => {handleCloseAEM(); handleOpenCloseCreateAndAddUniqueArticleModal();}}><AddCircle/>Créer un nouvel article unique</MenuItem>
                <Divider />
                <MenuItem disabled={true}>Notes, remises ou autre</MenuItem>
                <MenuItem onClick={_ => {handleCloseAEM(); handleOpenCloseAddEcritureModal();}}><Edit/>Ajouter une écriture libre</MenuItem>
            </Menu>
        </div>
    }

    return (
        <div className={"operations-details-wrapper"}>
            {data ?
                (<>
                        <div className={"operations-details-left-part"}>
                            <div className={"operations-details-wrapper-header"}>
                                Fiche opération n°{operationId}
                                <div>
                                    {/*<Button disabled={true} variant={"contained"} style={{marginRight: "10px"}}>*/}
                                    {/*    Statut : commande en {renderStatus(data.operation.statut)}*/}
                                    {/*</Button>*/}
                                    <Tooltip title={"Aller à la fiche client de " + data.operation.client_nom}>
                                        <Button variant={"contained"} onClick={() => handleRedirectToClientDetails(data.operation.client_id)} startIcon={<Engineering/>}>
                                            {data.operation.client_nom}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className={"operations-details-table-wrapper"}>
                                {renderStepper()}
                                {/*<div className={"operations-details-table-wrapper-title"}>*/}
                                {/*    Contenu de la commande ({data.sortedElements.length} entrées) :*/}
                                {/*</div>*/}

                                {renderAddElements()}

                                <TableContainer component={Paper} className={"operations-details-table-wrapper-table"}>
                                    <Table size="small" stickyHeader={true}>
                                        <TableHead>
                                            <TableRow>
                                                {categories.map((category) => {
                                                    return (
                                                        <TableCell key={category.key}>{category.title}</TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.sortedElements && data.sortedElements.map((element, k) => (
                                                <TableRow
                                                    className={(dragAndDrop.originalIndex === k ? "table-row-to-reorder " : " ") + (dragAndDrop.desiredNewIndex === k ? "table-row-desired " : " ") + "draggable-row"}
                                                    onDragEnter={_ => {
                                                        //Only update state when it changes !g
                                                        if (dragAndDrop.desiredNewIndex !== k) setDragAndDrop({originalIndex: dragAndDrop.originalIndex, desiredNewIndex: k})
                                                    }}
                                                    onDragStart={_ => {
                                                        setDragAndDrop({originalIndex: k, desiredNewIndex: null});
                                                    }}
                                                    onDragEnd={event => {
                                                        reorder(event);
                                                    }}
                                                    draggable={true}
                                                    key={element.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    {/*<TableCell>*/}
                                                    {/*    <Checkbox size={"small"} checked={selectedElements.indexOf(element) !== -1} onChange={() => handleElementTick(element)}/>*/}
                                                    {/*</TableCell>*/}
                                                    <TableCell>
                                                        {+(element.ordre) + 1}
                                                    </TableCell>
                                                    <TableCell style={{width: "350px"}}>
                                                        <div>
                                                            {element.libelle} <strong>{element.matiere_libelle && " - " + element.matiere_libelle}</strong>
                                                        </div>
                                                        <div id={`operations-details-table-wrapper-table-element-detail-${element.id}`} style={{display: "none"}}>
                                                            <div>
                                                                {element.valeur_epaisseur && "Epaisseur : " + element.valeur_epaisseur}
                                                            </div>
                                                            <div>
                                                                {element.valeur_longueur && "Longueur : " + element.valeur_longueur}
                                                            </div>
                                                            <div>
                                                                {element.valeur_developpe && "Développé : " + element.valeur_developpe}
                                                            </div>
                                                            {element.conditionsSpecifiques && element.conditionsSpecifiques.map((cs) => {
                                                                return(
                                                                    <div>{cs.libelle}: {cs.pourcentage}%</div>
                                                                )
                                                            })}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{renderElementActionBar(element)}</TableCell>
                                                    <TableCell>{element.prixHT ? (+element.prixHT).toFixed(2) + " €" : ""}</TableCell>
                                                    <TableCell>{element.quantite && element.quantite}</TableCell>
                                                    <TableCell>{element.quantite && element.prixHT && numberWithSpaces(Math.trunc((element.prixHT * element.quantite) * 100) / 100) + " €"}{element.pourcentage && element.pourcentage + " %"}</TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() => handleDeleteElement(element)}
                                                            style={{}}>
                                                            <Icon color={"primary"}>{"cancel"}</Icon>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </div>
                        <Paper className={"operations-details-right-part"}>
                            <div className={"operations-details-right-part-title"}>
                                Récapitulatif des prix
                            </div>
                            <div className={"price-resume"}>
                                <div className={"price-tag-container"}>
                                    <div className={"label"}>Total H.T.</div> <div className={"spacer"}></div> <div className={"money"}>{data.operation.prixHT} €</div>
                                </div>
                                <div className={"price-tag-container"}>
                                    <div className={"label"}>Dépollution</div> <div className={"spacer"}></div> <div className={"money"}>{(data.operation.prixHTPlusDepollution - data.operation.prixHT).toFixed(2)} €</div>
                                </div>
                                <div className={"price-tag-container"}>
                                    <div className={"label"}>T.V.A.</div> <div className={"spacer"}></div> <div className={"money"}>{Math.trunc(((data.operation.prixTTC - data.operation.prixHTPlusDepollution) * 100) / 100).toFixed(2)} €</div>
                                </div>

                                <div className={"price-tag-container"}>
                                    <div className={"label"}>Total T.T.C.</div> <div className={"spacer"}></div> <div className={"money"}><strong>{(Math.trunc(data.operation.prixTTC * 100) / 100).toFixed(2)} €</strong></div>
                                </div>
                            </div>
                            <div style={{margin: 20}}></div>

                            <div className={"operations-details-right-part-category-wrapper"}>
                                <div className={"operations-details-right-part-category-wrapper-title"}>
                                    Documents
                                </div>
                                <div style={{height: "1px", backgroundColor: "black", marginBottom: "10px"}}/>
                                {renderOperationDocuments()}
                            </div>
                            <div className={"operations-details-right-part-category-wrapper"} style={{marginTop: "20px"}}>
                                <div className={"operations-details-right-part-category-wrapper-title"}>
                                    Autres actions
                                </div>
                                <div style={{height: "1px", backgroundColor: "black", marginBottom: "10px"}}/>
                                {renderOperationActions()}
                            </div>
                        </Paper>
                    </>
                )
                :
                <div className={"operations-production-wrapper-content-loader-wrapper"}>
                    <Loader/>
                </div>
            }
            {isEditModalOpen &&
            <OperationDetailsEditModal isOpen={isEditModalOpen} handleClose={handleOpenCloseEditModal} triggerRefresh={handleSetTriggerRefresh} operation={data.operation}/>}
            {isCreateAndAddUniqueArticleModalOpen &&
            <OperationDetailsCreateAndAddUniqueArticle clientId={data.operation.client_id} ordre={data.sortedElements.length} operationId={data.operation.id}
                                                       isOpen={isCreateAndAddUniqueArticleModalOpen}
                                                       handleClose={handleOpenCloseCreateAndAddUniqueArticleModal} triggerRefetch={handleSetTriggerRefresh}
                                                       uniqueArticlesCatalogue={data.articleUniques} matieresCatalogue={data.matieres}/>}
            {isAddEcritureModalOpen && <OperationDetailsAddEcriture ordre={data.sortedElements.length} operationId={data.operation.id} isOpen={isAddEcritureModalOpen}
                                                                    handleClose={handleOpenCloseAddEcritureModal} triggerRefetch={handleSetTriggerRefresh}/>}
            {isAddArticleModalOpen &&
            <OperationDetailsAddArticle article={selectedElement && selectedElement.similaire && selectedElement.similaire.element} ordre={data.sortedElements.length} isOpen={isAddArticleModalOpen} handleClose={handleOpenCloseAddArticleModal}
                                        triggerRefetch={handleSetTriggerRefresh}
                                        articlesCatalogue={data.articles} operationId={data.operation.id}/>}
            {isCreateSerieModalOpen &&
                <OperationDetailsCreateSerieModal article={selectedElement && selectedElement.serie && selectedElement.serie.element} ordre={data.sortedElements.length} isOpen={isCreateSerieModalOpen} handleClose={handleOpenCloseCreateSerieModal}
                                            triggerRefetch={handleSetTriggerRefresh}
                                             operationId={data.operation.id}/>}
            {isAddUniqueArticleModalOpen &&
            <OperationDetailsAddUniqueArticle operationId={data.operation.id} ordre={data.sortedElements.length} isOpen={isAddUniqueArticleModalOpen}
                                              handleClose={handleOpenCloseAddUniqueArticleModal} triggerRefetch={handleSetTriggerRefresh}
                                              uniqueArticlesCatalogue={data.articleUniques}/>}
        </div>
    )
}

export default withRouter(OperationDetails)
