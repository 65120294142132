import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, useLocation
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import React, {useContext} from "react"
import {UserContext} from './contexts/UserContext'
import Login from './modules/login/Login'
import Header from "./modules/header/components/Header"
import Menu from "./modules/menu/Menu"
import Home from "./modules/home/components/Home"
import Loader from "./shared/loader/Loader";
import OperationsProduction from "./modules/operations/production/OperationsProduction";
import OperationsDelivery from "./modules/operations/delivery/OperationsDelivery";
import OperationsQuote from "./modules/operations/quote/OperationsQuote";
import OperationsBill from "./modules/operations/bills/OperationsBill";
import ProductsGeneralCatalogue from "./modules/products/generalCatalogue/ProductsGeneralCatalogue";
import ProductsUniqueProductsCatalogue from "./modules/products/uniqueProducts/ProductsUniqueProductsCatalogue";
import NewOperation from "./modules/operations/newOperation/NewOperation";
import PricingRulesManager from './modules/pricingRules/PricingRulesManager'
import OperationsArchives from "./modules/operations/archives/OperationsArchives";
import Statistics from "./modules/stats/stats/Statistics"
import Exports from "./modules/stats/exports/Exports"
import Providers from "./modules/providers/providers/Providers"
import ProviderDetail from "./modules/providers/providers/ProviderDetail"
import ProvidersOrdersList from "./modules/providers/orders/ProvidersOrdersList"
import Clients from "./modules/clients/Clients";
import OperationDetails from "./modules/operations/operationDetails/OperationDetails";
import {ConfModalContext} from "./contexts/ModalContext"

function App() {
    const {user, jwt, isLoadingUserContext} = useContext(UserContext)
    const {renderModal} = useContext(ConfModalContext)
    const isInMobileContext = window.location.pathname?.match(/^\/mobile.*/)?.length > 0;

    console.log(isInMobileContext)
    if(isLoadingUserContext){
        return(
            <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Loader />
            </div>

        )
    }
    if (!user && !jwt) {
        return (
            <Login/>
        )
    } else {
        return (
            <Router>
                {renderModal()}
                <Toaster position="top-right"/>
                <div style={{display: "flex", width: "100%", maxWidth:"100vw"}}>
                    <Route component={Menu}/>
                    <div style={{display: "flex", flexDirection: "column", width:"calc(100% - 320px)" ,maxWidth:"calc(100% - 320px)", position:'relative'}}>
                        <Route component={Header}/>
                        {/*CONTENT*/}
                        <div className={"router-content-wrapper"}>
                            <div className="noscrollbar main-card-content" style={{height:"100%", width:"100%", overflowY:"auto"}}>
                                <Switch>
                                    <Route exact path="/">
                                        {<Redirect to="/home" />}
                                    </Route>
                                    <Route exact path={'/home'} component={Home}/>
                                    <Route exact path={'/operations/production'} component={OperationsProduction}/>
                                    <Route exact path={'/operations/delivery'} component={OperationsDelivery}/>
                                    <Route exact path={'/operations/quotes'} component={OperationsQuote}/>
                                    <Route exact path={'/operations/bills'} component={OperationsBill}/>
                                    <Route exact path={'/operations/records'} component={OperationsArchives}/>
                                    <Route exact path={'/operations/create'} component={NewOperation}/>
                                    <Route exact path={'/operations/operation/detail/:operationId'} component={OperationDetails}/>
                                    <Route exact path={'/articles/general'} component={ProductsGeneralCatalogue}/>
                                    <Route exact path={'/articles/uniques'} component={ProductsUniqueProductsCatalogue}/>
                                    <Route exact path={'/rules/manager'} component={PricingRulesManager}/>
                                    <Route exact path={'/clients/list/'} component={Clients}/>
                                    <Route exact path={'/clients/list/:clientId'} component={Clients}/>
                                    <Route exact path={'/stats/full'} component={Statistics}/>
                                    <Route exact path={'/stats/exports'} component={Exports}/>
                                    <Route exact path={'/providers/list'} component={Providers}/>
                                    <Route exact path={'/providers/orders/list'} component={ProvidersOrdersList}/>
                                    <Route exact path={'/providers/list/:providerId'} component={ProviderDetail}/>
                                </Switch>
                            </div>
                        </div>

                    </div>
                </div>
            </Router>
        )
    }

}

export default App;
