import {proformaPDFTemplate, deliveryReceiptTemplate, quoteTemplate, billTemplate, operationsList, avoirTemplate, providerOrderTemplate} from "../../../shared/pdf/PDFTemplates";
import {APIget, APIpost, APIpatch, APIdelete, APIput} from "../../../services/API";


export const getOrdersInProduction = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/get/commande`)
            .then((operations) => {
                resolve(operations)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getArchivedOperations = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/get/archive`)
            .then((operations) => {
                resolve(operations)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getTarifsAndDeveloped = (articleId, matiereId, payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/commande/tarifs/developpe/for/article/${articleId}/and/matiere/${matiereId}`, payload)
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getArticleInformations = (articleId) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/article/commande/required/informations/${articleId}`)
            .then((article) => {
                resolve(article)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createArticleCommande = (payload, operationId) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/commande/create/from/operation/${operationId}`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getOrdersInDelivery = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/get/livraison`)
            .then((operations) => {
                resolve(operations)
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export const getPendingBills = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/get/facture`)
            .then((operations) => {
                resolve(operations)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getCurrentQuotes = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/get/devis`)
            .then((operations) => {
                resolve(operations)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createArticleUniqueCommande = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/unique/commande/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createArticleUnique = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/unique/create`, payload)
            .then((articleUniqueId) => {
                resolve(articleUniqueId)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getAllClients = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/client/fetch/all/and/contacts`)
            .then((clients) => {
                resolve(clients)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createEcriture = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/ecriture/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createOperation = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/operation/create`, payload)
            .then((operationCreatedId) => {
                resolve(operationCreatedId)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createFacture = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/facture/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getOperationDetail = (operationId) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/operation/${operationId}/detail`)
            .then((operation) => {
                resolve(operation)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const downloadOperationPDF = (orderData, documentType) => {
    if (!documentType) {
        return
    }
    let template = null;
    if (documentType === "proforma") {
        template = proformaPDFTemplate(orderData)
    } else if (documentType === 'deliveryReceipt') {
        template = deliveryReceiptTemplate(orderData)
    } else if (documentType === 'quote') {
        template = quoteTemplate(orderData)
    } else if (documentType === 'basicBill') {
        template = billTemplate(orderData, 'basic')
    } else if (documentType === 'detailedBill') {
        template = billTemplate(orderData, 'detailed')
    } else if (documentType === 'operationsInProductionList' || documentType === 'operationsInProductionListForAClient') {
        template = operationsList(orderData, documentType)
    } else if (documentType === 'avoir') {
        template = avoirTemplate(orderData)
    } else if (documentType === 'providerOrder') {
        template = providerOrderTemplate(orderData)
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const payload = JSON.stringify({
        html: template,
        // textFooter:" Norme AFNOR NF P03-001. En l'absence de dispositions particulières contraires, la norme AFNOR NF P-03001 de décembre 2000 vaut pièce contractuelle complémentaire. " +
        //     "Le vendeur se réserve, jusqu'à leur complet paiement, la propriété des fournitures éligibles à la revendication prévue par l'article" +
        //     "L.264-16 du code du commerce." +
        //     "36 rue Mathieu 93400 SAINT-OUEN Tl: 01 40 11 33 40 Fax: 01 40 11 33 11 decord@renovachrome.fr" +
        //     "SARL au capital de 8 000 Euros RCS Bobigny B572 142 347 APE 2561Z TVA intracommunautaire FR2557214234700028"
        //     ,
        // noMargin: true
    })
    const requestOptions = {

        method: 'POST',
        headers: myHeaders,
        body: payload,
        redirect: 'follow'
    };
    fetch("https://yan.erp.hubup.cloud/pdf/make/from/html", requestOptions)
        .then(resp => resp.arrayBuffer()).then(resp => {
        // set the blog type to final pdf
        const file = new Blob([resp], {type: 'application/pdf'});
        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        // window.open(fileURL)
        link.setAttribute("target", "_blank")
        // link.download = downloadLink;
        link.click();
    });
}

export const changeOperationStatus = (payload, operationId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/operation/${operationId}/patch`, payload)
            .then((operation) => {
                resolve(operation)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editOperation = (payload, operationId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/operation/${operationId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteFacture = (factureId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/facture/${factureId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteOperation = (operationId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/operation/${operationId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteEcriture = (ecritureId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/ecriture/${ecritureId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteUniqueArticle = (articleId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/article/unique/commande/${articleId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteStandardArticle = (articleId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/article/commande/${articleId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export const registerPayment = (payload, factureId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/facture/${factureId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const patchEcriture = (payload, ecritureId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/ecriture/${ecritureId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const patchArticleUniqueCommande = (payload, articleUniqueCommandeId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/article/unique/commande/${articleUniqueCommandeId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const patchArticleCommande = (payload, articleUniqueCommandeId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/article/commande/${articleUniqueCommandeId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteAvoir = (avoirId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/avenant/${avoirId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createAvoir = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/avenant/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/**
 * elements : Array<{className: "AU"|"AUC"|"E", order: number, id: number}>
 */
export const reorderOperationElements = (operation: number, elements:Array<{className: "AU"|"AUC"|"E", order: number, id: number}>)  => {
    return new Promise((resolve, reject) => {
        APIput(`/api/operation/${operation}/reorder`, elements)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    });
}

