import {APIpost, APIget} from "../../../services/API";

export const getGroupsFinishStatistics = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/stats/ca/group/finition/between/dates`, payload)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getClientsStatistics = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/stats/ca/client/between/dates`, payload)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getMonthlyTurnover = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/stats/ca/monthly`, payload)
            .then((stats) => {
                resolve(stats)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getSalesSummary = (start, end, jwt) => {
    return new Promise((resolve, reject) => {
        let myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer " + jwt);
        myHeaders.append("content-type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://" + process.env.REACT_APP_API_URL + `/api/spreadsheet/export/ventes/between/dates/${start}/${end}`, requestOptions)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
