import React, {useState, useEffect} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    DialogContent,
    TextField,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Alert
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {getArticleInformations, getTarifsAndDeveloped, createArticleCommande} from "../api/OperationsAPI";
import {getAllSpecificConditions} from "../../products/api/ProductsAPI";
import toast from "react-hot-toast";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const OperationDetailsAddArticle = ({article, isOpen, handleClose, articlesCatalogue, triggerRefetch, ordre, operationId}) => {

    const [step, setStep] = useState(1)
    const [csSelectOpen, setCsSelectOpen] = useState(false)
    const [articlesCat, setArticlesCat] = useState(articlesCatalogue)
    const [matieresCat, setMatieresCat] = useState(null)
    const [tarifsCat, setTarifsCat] = useState(null)
    const [loiDvp, setLoiDvp] = useState(null)
    const [developedValue, setDevelopedValue] = useState(article ? article.valeur_developpe : null)
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [selectedFinish, setSelectedFinish] = useState(null)
    const [selectedTarif, setSelectedTarif] = useState(null)
    const [libelle, setLibelle] = useState(null)
    const [quantite, setQuantite] = useState(1)
    const [length, setLength] = useState("")
    const [thickness, setThickness] = useState("")
    const [dimensions, setDimensions] = useState(null)
    const [pointsSeuilsEpaisseur, setPointsSeuilsEpaisseur] = useState(null)
    const [pointsSeuilsLongueur, setPointsSeuilsLongueur] = useState(null)
    const [pourcentageLongueur, setPourcentageLongueur] = useState(null)
    const [pourcentageEpaisseur, setPourcentageEpaisseur] = useState(null)
    const [prixUnitaireDefinitif, setPrixUnitaireDefinitif] = useState(null)
    const [allSpecificConditions, setAllSpecificConditions] = useState(null)
    const [selectedSpecificConditions, setSelectedSpecificConditions] = useState(null)
    const [specificConditionsIds, setSpecificConditionsIds] = useState([])
    const [prixHTBase, setPrixHTBase] = useState(null)
    const [prixALaDimension, setPrixALaDimension] = useState(null)
    const [majorationLongueur, setMajorationLongueur] = useState(null)
    const [majorationEpaisseur, setMajorationEpaisseur] = useState(null)
    const [priceAllIncluded, setPriceAllIncluded] = useState(0)

    useEffect(() => {
        selectedArticle && handleGetArticleInformations()
        handleGetAllSpecificConditions()
    }, [selectedArticle])

    useEffect(() => {
        if(article){
            handleSelectArticle(article)
        }
    }, [article])

    useEffect(() => {
        if(matieresCat && article){
            handleSelectFinish(article)
        }
    }, [matieresCat])

    useEffect(() => {
        if (majorationLongueur) {
            calculateSpecificConditions()
        }
    }, [majorationLongueur])

    useEffect(() => {
        if (allSpecificConditions) {
            let totalPrice = parseFloat(prixALaDimension) + parseFloat(majorationLongueur) + parseFloat(majorationEpaisseur)
            const scValues = allSpecificConditions.map((sc) => sc.majoration)
            for (let value of scValues) {
                if (value) {
                    totalPrice += parseFloat(value)
                }
            }
            setPriceAllIncluded(totalPrice)
            setPrixUnitaireDefinitif(totalPrice.toFixed(2))
        }

    }, [selectedSpecificConditions])

    const handleSetLibelle = (value) => {
        setLibelle(value)
    }


    const handleSetQuantite = (value) => {
        setQuantite(value)
    }

    const handleSetLength = (value) => {
        setLength(parseInt(value))
    }

    const handleSetThickness = (value) => {
        setThickness(parseInt(value))
    }

    const handleFinalPrice = (value) => {
        setPrixUnitaireDefinitif((+value).toFixed(2))
    }

    const handleSelectArticle = (e) => {
        const selectedArticle = articlesCatalogue.find((article) => article.id === (e.article_id || e.target.value))
        setSelectedArticle(selectedArticle)
        setLibelle(selectedArticle.libelle)
        setStep(2)
    }

    const handleGetArticleInformations = async () => {
        const articleInformations = await getArticleInformations(selectedArticle.id)
        console.log(articleInformations)
        for (let dimension of articleInformations.dimensions) {
            dimension.value = ""
        }
        let loiDvp = articleInformations.loiDeveloppe;
        loiDvp.dimensions = articleInformations.dimensions;
        loiDvp.displayableFormula = loiDvp.calcul;
        loiDvp.displayableRepresentation = loiDvp.representation;
        for(let dim of loiDvp.dimensions) {
            loiDvp.displayableFormula = loiDvp.displayableFormula.replace("["+dim.diminutif+"]", " "  + dim.libelle + " ")
            loiDvp.displayableRepresentation = loiDvp.displayableRepresentation.replace("["+dim.diminutif+"]", " "  + dim.libelle + " ")
        }


        setLoiDvp(loiDvp);
        console.log(loiDvp);
        setMatieresCat(articleInformations.matieres)
        setDimensions(articleInformations.dimensions)
        setPointsSeuilsEpaisseur(articleInformations.pointSeuilsEpaisseur.sort((a, b) => a.point - b.point))
        setPointsSeuilsLongueur(articleInformations.pointSeuilsLongueur.sort((a, b) => a.point - b.point))
    }

    const handleSelectFinish = (e) => {
        const selectedFinish = matieresCat.find((article) => article.id === (e.matiere_id || e.target.value))
        setSelectedFinish(selectedFinish)
        setStep(3)
    }

    const handleSelectTarif = (e) => {
        const selectedTarif = tarifsCat.find((tarif) => tarif.id === e.target.value)
        setSelectedTarif(selectedTarif)
    }

    const handleSetDimensionsValues = (index, value) => {
        let dim = dimensions.slice()
        dim[index].value = value
        setDimensions(dim)
    }

    const handleCreateArticle = async () => {

        const payload = {
            libelle: libelle,
            quantite: quantite,
            articleId: selectedArticle.id,
            matiereId: selectedFinish.id,
            prixHT: prixUnitaireDefinitif,
            ordre: ordre,
            conditionSpecifiqueIds: specificConditionsIds,
            valeurEpaisseur: thickness.toString(),
            valeurLongueur: length.toString(),
            valeurDeveloppe: developedValue.toString(),
            tarifId: selectedTarif.id
        }
        try {
            await createArticleCommande(payload, operationId)
            triggerRefetch()
            toast.success("L'article standard a été créé avec succès'")
            handleClose(null)
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la création de l'article standard")
        }
    }

    const handleGetAllSpecificConditions = async () => {
        const conditions = await getAllSpecificConditions()
        setAllSpecificConditions(conditions)
    }

    const handleGetPrices = async () => {
        let dimensionsToSend = {}
        for (let dimension of dimensions) {
            dimensionsToSend[dimension.diminutif] = dimension.value
        }
        const result = await getTarifsAndDeveloped(selectedArticle.id, selectedFinish.id, dimensionsToSend)
        setTarifsCat(result.tarifs)
        setDevelopedValue(result.value.toFixed(2))
        setStep(5)
    }

    const handleGetFinalPrice = () => {
        setStep(6)
        let pourcentageLongueur = 0
        let pourcentageEpaisseur = 0
        for (let pointSeuil of pointsSeuilsLongueur) {
            if (length > parseInt(pointSeuil.point)) {
                pourcentageLongueur = pointSeuil.pourcentage
            }
        }
        for (let pointSeuil of pointsSeuilsEpaisseur) {
            if (thickness > parseInt(pointSeuil.point)) {
                pourcentageEpaisseur = pointSeuil.pourcentage
            }
        }
        setPourcentageLongueur(pourcentageLongueur)
        setPourcentageEpaisseur(pourcentageEpaisseur)
        setPrixHTBase(selectedTarif.prix)
        setPrixALaDimension(((selectedTarif.prix) * (length / 100)).toFixed(2))
        setMajorationLongueur(((selectedTarif.prix) * (length / 100) * (pourcentageLongueur / 100)).toFixed(2))
        setMajorationEpaisseur(((selectedTarif.prix) * (length / 100) * (pourcentageEpaisseur / 100)).toFixed(2))
    }

    const handleSetSpecificCondition = (event) => {
        const {target: {value}} = event;
        setCsSelectOpen(false);
        setSpecificConditionsIds(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const calculateSpecificConditions = () => {
        const priceWithLengthAndThickness = parseFloat(prixALaDimension) + parseFloat(majorationEpaisseur) + parseFloat(majorationLongueur)
        const specificConditionsFiltered = []
        for (let scid of specificConditionsIds) {
            const condition = allSpecificConditions.find((sc) => scid === sc.id)
            condition.majoration = (priceWithLengthAndThickness * (parseInt(condition.pourcentage) / 100))
            specificConditionsFiltered.push(condition)
        }
        setSelectedSpecificConditions(specificConditionsFiltered)
    }

    return (
        <Dialog open={isOpen} onClose={() => handleClose(null)} maxWidth={"lg"}>
            <DialogTitle>Ajout d'un article standard</DialogTitle>
            <DialogContent>
                <div className={"products-edit-unique-article-text-fields-wrapper"}>
                    <FormControl sx={{minWidth: 350}}>
                        <InputLabel>Choisssez un article standard</InputLabel>
                        <Select
                            // size={"small"}
                            disabled={step > 1}
                            style={{marginBottom: "12px"}}
                            label={'Articles standard disponibles'}
                            className={""}
                            onChange={handleSelectArticle}
                            value={selectedArticle && selectedArticle.id}
                        >
                            {articlesCat && articlesCat.map((article) => {
                                return (
                                    <MenuItem key={article.id} value={article.id}>{article.libelle}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {
                        step > 1 && selectedArticle &&
                        <>
                            <Alert severity="info" style={{marginTop: 5, marginBottom: 10}}>La finition permet de connaître les valeurs de développé pour l'application des tarifs</Alert>
                            <FormControl sx={{minWidth: 350}}>
                                {!selectedFinish && <InputLabel>Choisissez une finition</InputLabel>}
                                <Select
                                    // size={"small"}
                                    disabled={!article && step > 3}
                                    style={{marginBottom: "12px"}}
                                    label={'Finitions disponibles'}
                                    className={""}
                                    onChange={handleSelectFinish}
                                    value={selectedFinish && selectedFinish.id}
                                >
                                    {matieresCat && matieresCat.map((article) => {
                                        return (
                                            <MenuItem key={article.id} value={article.id}
                                            >{article.libelle}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </>


                    }
                    {
                        <>
                        {step > 2 && <Alert severity="info" style={{marginTop: 5, marginBottom: 10}}>Pour l'article <strong>{loiDvp.libelle}</strong><br/>le développé sera calculé en utilisant la formule suivante : <br/> <strong>{loiDvp.displayableFormula}</strong> <br/> Il sera représenté dans le devis de cette façon : <br/> <strong>{loiDvp.displayableRepresentation}</strong></Alert>}
                            {step > 2 && dimensions &&
                            dimensions.map((dim, i) => {
                                return (
                                    <TextField
                                        size={"small"}
                                        disabled={step > 3}
                                        inputProps={{min: 0}}
                                        type={'number'}
                                        style={{marginBottom: "12px", marginLeft: 150}}
                                        value={article ? article.dimensions[i].value : dim.value}
                                        onChange={(e) => handleSetDimensionsValues(i, e.target.value)}
                                        variant="outlined"
                                        label={dim.libelle}
                                    />
                                )

                            })
                            }
                            {
                                selectedArticle && selectedFinish && step < 4 &&
                                <Button
                                    style={{marginBottom: "12px"}}
                                    variant={"contained"}
                                    disabled={dimensions.filter(e => e.value === "").length}
                                    onClick={() => handleGetPrices()}
                                >
                                    Consulter les tarifs disponibles
                                </Button>
                            }
                        </>
                    }
                    {step > 3 && tarifsCat && developedValue &&
                    <>
                        <Alert severity={"info"}   style={{marginBottom: "12px"}}>Valeur du développé calculé : <strong>{developedValue} mm</strong></Alert>

                        <FormControl sx={{minWidth: 350}}>
                            <InputLabel>Choisissez un tarif</InputLabel>
                            <Select
                                disabled={step === 6}
                                style={{marginBottom: "12px"}}
                                label={'Choisissez un tarif'}
                                className={""}
                                onChange={handleSelectTarif}
                            >
                                {tarifsCat && tarifsCat.map((tarif) => {
                                    return (
                                        <MenuItem key={tarif.id} value={tarif.id}
                                        >{tarif.libelle} - {tarif.prix} €</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>


                    </>
                    }
                    {
                        <>
                            {step > 4 && selectedTarif &&
                            <>
                                <TextField
                                    size={"small"}
                                    disabled={step === 6}
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    style={{marginBottom: "12px"}}
                                    value={length}
                                    onChange={(e) => handleSetLength(e.target.value)}
                                    variant="outlined"
                                    label={"Longueur de l'article en mm"}
                                />
                                <TextField
                                    size={"small"}
                                    disabled={step === 6}
                                    style={{marginBottom: "12px"}}
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    value={thickness}
                                    onChange={(e) => handleSetThickness(e.target.value)}
                                    variant="outlined"
                                    label={"Epaisseur de l'article en mm"}
                                />
                                <TextField
                                    size={"small"}
                                    disabled={step === 6}
                                    inputProps={{min: 0}}
                                    type={'number'}
                                    style={{marginBottom: "12px"}}
                                    value={quantite}
                                    onChange={(e) => handleSetQuantite(e.target.value)}
                                    variant="outlined"
                                    label={"Quantité"}
                                />
                                <FormControl sx={{marginBottom: "12px"}}>
                                    <InputLabel>Choisissez les condition(s) spécifique(s) associées le cas échéant</InputLabel>
                                    <Select
                                        disabled={step === 6}
                                        label={"Choisissez les condition(s) spécifique(s) associées le cas échéant"}
                                        multiple
                                        open={csSelectOpen}
                                        onClick={_ => setCsSelectOpen(!csSelectOpen)}
                                        value={specificConditionsIds}
                                        onChange={handleSetSpecificCondition}
                                        MenuProps={{
                                            style: {
                                                maxHeight: "200px",
                                            },
                                        }}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => {
                                                    return (
                                                        <Chip size={"small"} key={value} label={allSpecificConditions.find((sc) => sc.id === value).libelle}/>
                                                    )
                                                })
                                                }
                                            </Box>
                                        )}
                                    >
                                        {
                                            allSpecificConditions && allSpecificConditions.map((sc) => {
                                                    return (
                                                        <MenuItem key={sc.id} value={sc.id}>{sc.libelle}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    length && thickness && quantite && step < 6 &&
                                    <Button
                                        style={{marginBottom: "12px"}}
                                        variant={"contained"}
                                        onClick={() => handleGetFinalPrice()}
                                    >
                                        Calculer le tarif final
                                    </Button>
                                }

                            </>
                            }
                        </>
                    }
                    {
                        <>
                            {step > 5 &&
                            <>
                                <TableContainer component={Paper} style={{marginBottom: "12px"}}>
                                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Element</TableCell>
                                                <TableCell>Prix</TableCell>
                                                <TableCell>Quantité</TableCell>
                                                <TableCell>Total</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                            >
                                                <TableCell component="th" scope="row">
                                                    Prix unitaire HT de base
                                                    ({selectedTarif.plage_de_validite_libelle} - {selectedTarif.libelle}, {selectedTarif.unite === "ml" ? 'au mètre linéaire' : "à la surface"})
                                                </TableCell>
                                                <TableCell>{prixHTBase} €</TableCell>
                                                <TableCell/>
                                                <TableCell>{prixHTBase} €</TableCell>
                                            </TableRow>
                                            <TableRow
                                            >
                                                <TableCell component="th" scope="row">
                                                    Prix à la dimension ({length / 100} mètre(s) linéaire(s)})
                                                </TableCell>
                                                <TableCell>{prixALaDimension} €</TableCell>
                                                <TableCell>{quantite}</TableCell>
                                                <TableCell>{prixALaDimension * quantite} €</TableCell>
                                            </TableRow>
                                            <TableRow
                                            >
                                                <TableCell component="th" scope="row">
                                                    Majoration longueur ({pourcentageLongueur} %)
                                                </TableCell>
                                                <TableCell>{majorationLongueur} €</TableCell>
                                                <TableCell>{quantite}</TableCell>
                                                <TableCell>{majorationLongueur * quantite} €</TableCell>
                                            </TableRow>
                                            <TableRow
                                            >
                                                <TableCell component="th" scope="row">
                                                    Majoration épaisseur ({pourcentageEpaisseur} %)
                                                </TableCell>
                                                <TableCell>{majorationEpaisseur} €</TableCell>
                                                <TableCell>{quantite}</TableCell>
                                                <TableCell>{majorationEpaisseur * quantite} €</TableCell>
                                            </TableRow>
                                            {selectedSpecificConditions &&
                                            selectedSpecificConditions.map((sc) => {
                                                return (
                                                    <TableRow
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            Condition spécifique {sc.libelle} ({sc.pourcentage} %)
                                                        </TableCell>
                                                        <TableCell>{sc.majoration && (sc.majoration).toFixed(2)} €</TableCell>
                                                        <TableCell>{quantite}</TableCell>
                                                        <TableCell>{sc.majoration && (sc.majoration * quantite).toFixed(2)} €</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                            <TableRow
                                            >
                                                <TableCell component="th" scope="row" style={{fontWeight: "bold"}}>
                                                    TOTAL
                                                </TableCell>
                                                <TableCell
                                                    style={{fontWeight: "bold"}}>{priceAllIncluded.toFixed(2)} €</TableCell>
                                                <TableCell style={{fontWeight: "bold"}}>{quantite}</TableCell>
                                                <TableCell
                                                    style={{fontWeight: "bold"}}>{(priceAllIncluded * quantite).toFixed(2)} €</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TextField
                                    size={"small"}
                                    style={{marginBottom: "12px"}}
                                    value={libelle}
                                    onChange={(e) => handleSetLibelle(e.target.value)}
                                    variant="outlined"
                                    label={"Libellé définitif"}
                                />
                                <TextField
                                    size={"small"}
                                    style={{marginBottom: "12px"}}
                                    value={prixUnitaireDefinitif}
                                    onChange={(e) => handleFinalPrice(e.target.value)}
                                    variant="outlined"
                                    label={"Prix unitaire définitif"}
                                />
                                <Button
                                    style={{marginBottom: "12px"}}
                                    variant={"contained"}
                                    onClick={() => handleCreateArticle()}
                                >
                                    Enregistrer l'article
                                </Button>
                            </>
                            }
                        </>
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default OperationDetailsAddArticle
