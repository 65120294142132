import React from "react"
import {Button, Paper, Icon} from "@mui/material"
import {withRouter} from "react-router-dom"


const ClientDetailsbis = ({
                              selectedClient,
                              handleOpenEditCreateClientModal,

                          }) => {

    return (
        <Paper className={'clients-client-details-wrapper'}>
            <div className={"clients-client-details-wrapper-title"}>

                <Icon style={{fontSize:18, color:"#1079FC", marginRight:10, marginLeft:4}}>assignment</Icon>

                Informations sur le client
                <div style={{flex:1}}/>

                <Button variant={"outlined"} style={{color:"#1079FC", borderColor:"#1079FC"}}
                        size={"small"}
                        onClick={() => handleOpenEditCreateClientModal(selectedClient)}
                >Modifier</Button>
            </div>
            <div style={{height: "1px", width: "100%", backgroundColor: "#CCCCCC", marginBottom: "12px"}}/>
            <div className={"clients-client-details-wrapper-content"}>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>Dénomination </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.nom} {selectedClient.entreprise !== "1" && selectedClient.prenom}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>SIRET </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.siret}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>Adresse </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.adresse}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>Ville </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.code_postal} {selectedClient.ville}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>Email </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.email}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>Téléphone </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.telephone}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>Fax </strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.fax}
                </div>
                <div className={"client-details-left-line-wrapper"}>
                    <div className={"client-details-left-line-content"}>
                        <strong style={{fontSize:14, display:"inline-block", marginRight:12}}>TVA applicable par défaut</strong>
                        <div style={{flex:1, height:1, backgroundColor:"#EEEEEE"}}/>
                    </div>
                    {selectedClient.tva === "1" ? "Oui" : "Non"}
                </div>
            </div>
        </Paper>
    )
}

export default withRouter(ClientDetailsbis)
