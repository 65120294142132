import {APIget, APIpost, APIdelete, APIpatch} from "../../../services/API";


export const getAllArticles = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/articles/fetch/all`)
            .then((articles) => {
                resolve(articles)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getAllFinish = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/matieres/fetch/all`)
            .then((finish) => {
                resolve(finish)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getAllSpecificConditions = () => {
    return new Promise((resolve, reject) => {
        APIget(`/api/conditions/specifiques/fetch/all`)
            .then((specificConditions) => {
                resolve(specificConditions)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getARangeOfUniqueArticles = (limit, offset) => {
    return new Promise((resolve, reject) => {
        APIget(`/api/article/unique/get/limit/${limit}/offset/${offset}`)
            .then((uniqueArticles) => {
                resolve(uniqueArticles)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const searchInUniqueArticles = (searchValue) => {
    const payload = {research: searchValue}
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/unique/search`, payload)
            .then((uniqueArticles) => {
                resolve(uniqueArticles)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getUniqueArticlesWithPictures = (searchValue) => {
    const payload = {research: searchValue}
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/unique/filtered/only/files`, payload)
            .then((uniqueArticles) => {
                resolve(uniqueArticles)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteUniqueArticle = (articleId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/article/unique/${articleId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createSpecificCondition = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/conditions/specifiques/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const editSpecificCondition = (payload, conditionId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/conditions/specifiques/${conditionId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteSpecificCondition = (conditionId) => {
    return new Promise((resolve, reject) => {
        APIdelete(`/api/conditions/specifiques/${conditionId}/delete`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createFinish = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/matieres/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createArticle = (payload) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/article/create`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const patchUniqueArticle = (payload, articleId) => {
    return new Promise((resolve, reject) => {
        APIpatch(`/api/article/unique/${articleId}/patch`, payload)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const duplicateMatiere = (matiereId) => {
    return new Promise((resolve, reject) => {
        APIpost(`/api/matiere/${matiereId}/duplicate/v2`)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
    })
}
