import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {Tooltip} from '@mui/material';
import {ExitToApp} from '@mui/icons-material';
import './Menu.scss'
import logo_renova from "./../../assets/logo_renova.png"
import {UserContext} from "./../../contexts/UserContext"
import {MenuButton} from "./Buttons"


const Menu = () => {
    const {user, removeUser} = useContext(UserContext)

    const handleLogout = () => {
        removeUser()
    }

    const menuItems = [
        {
            name: "Opérations",
            icon: "business_center",
            link: null,
            submenu: [
                {
                    title: "Liste des commandes en production",
                    name: "production",
                    icon: "build",
                    link: "/operations/production"
                },
                {
                    title: "Liste des commandes en livraison",
                    name: "Livraison",
                    icon: "local_shipping",
                    link: "/operations/delivery"
                },
                {
                    title: "Liste des devis en cours",
                    name: "Devis",
                    icon: "content_paste",
                    link: "/operations/quotes"
                },
                {
                    title: "Liste des factures en attente de réglement",
                    name: "Factures",
                    icon: "euro",
                    link: "/operations/bills"
                },
                // {
                //     title: "Opérations - Archives",
                //     name: "Archives",
                //     icon: "folder",
                //     link: "/operations/records"
                // },
                {
                    title: "Opérations - Créer une opération",
                    name: "Créer opération",
                    icon: 'add',
                    link: "/operations/create"
                }

            ]
        },
        {
            name: "Statistiques",
            icon: "pie_chart",
            link: null,
            submenu: [
                {
                    title: "Statistiques - Statistiques complètes",
                    name: "Statistiques",
                    icon: "equalizer",
                    link: "/stats/full"
                },
                {
                    title: "Statistiques - Export de fichiers",
                    name: "Export de fichiers",
                    icon: "upload_file",
                    link: "/stats/exports"
                }
            ]
        },
        {
            name: "Clientèle",
            icon: "people",
            link: null,
            submenu: [
                {
                    title: "Clients - Liste",
                    name: "Clients",
                    icon: "format_list_numbered",
                    link: "/clients/list"
                }
            ]
        },
        {
            name: "Fournisseurs",
            icon: "shopping_basket",
            link: null,
            submenu: [
                {
                    title: "Fournisseurs - Liste",
                    name: "Fournisseurs",
                    icon: "format_list_numbered",
                    link: "/providers/list"
                },
                {
                    title: "Fournisseurs - Commandes fournisseurs",
                    name: "Commandes fournisseurs",
                    icon: "format_list_numbered",
                    link: "/providers/orders/list"
                },
            ]
        },
        {
            name: "Articles & finitions",
            icon: "article",
            link: null,
            submenu: [
                {
                    title: "Articles & finitions - Catalogue général",
                    name: "Catalogue général",
                    icon: "format_list_numbered",
                    link: "/articles/general"
                },
                {
                    title: "Articles & finitions - Catalogue articles uniques",
                    name: "Cat. articles uniques",
                    icon: "list",
                    link: "/articles/uniques"
                },
                {
                    title: "Articles & finitions - Catalogue article commande isolée",
                    name: "Article commande isolée",
                    icon: "search",
                    link: "/articles/search"
                },
            ]
        },
        {
            name: "Lois de tarification",
            icon: "gavel",
            link: null,
            submenu: [
                {
                    title: "Lois de tarification - Gestionnaire des lois",
                    name: "Gestionnaire des lois",
                    icon: "settings",
                    link: "/rules/manager"
                },
            ]
        }


    ]

    return (
        <div className={'menu-wrapper'}>
            <div className={'menu-wrapper-header'}>
                <Link to={'/home'}>
                    <img src={logo_renova} className={"menu-wrapper-header-logo"} alt="renova chrome logo"/>
                </Link>
                <div className={"menu-wrapper-header-credentials"}>
                     <span>
                         {user && user.prenom}
                    </span>
                    <span>
                     &nbsp;
                    </span>
                    <span>
                       {user && user.nom}
                    </span>
                    <Tooltip title={"Déconnexion"} placement={"bottom"} className={'menu-wrapper-header-logout'}>
                        <ExitToApp onClick={() => handleLogout()}/>
                    </Tooltip>
                </div>
                <div className={'menu-wrapper-header-firstname-circle-wrapper'}>
                    <div className={'menu-wrapper-header-firstname-circle'}>
                        <span>
                           {user && user.prenom && user.nom.slice(0, 1).toUpperCase()}
                        </span>
                    </div>
                </div>
            </div>
            <div className={'menu-wrapper-body noscrollbar'}>
                {menuItems.map((item) => {
                    return (
                        <MenuButton key={item.title} cluster={item}/>
                    )
                })}
            </div>

        </div>
    )
}

export default Menu
