import React, {useState, useEffect} from "react"
import "./PricingRules.scss"
import {getDevelopedRules, deleteDevelopedRule, getThicknessRules, deleteThicknessRule, getLengthRules, deleteLengthRule} from "./api/PricingRulesAPI";
import PricingRulesThicknessRules from "./PricingRulesThicknessRules";
import PricingRulesDevelopedRules from "./PricingRulesDevelopedRules"
import PricingRulesLengthRules from "./PricingRulesLengthRules";
import toast from 'react-hot-toast';

const PricingRulesManager = () => {

    const [developedRules, setDevelopedRules] = useState(null)
    const [thicknessRules, setThicknessRules] = useState(null)
    const [lengthRules, setLengthRules] = useState(null)
    const [modalMode, setModalMode] = useState(null)
    const [triggerRefetch, setTriggerRefetch] = useState(true)

    useEffect(() => {
        handleGetDevelopedRules()
        handleGetThicknessRules()
        handleGetLengthRules()
    }, [triggerRefetch])

    const manageDevelopedRules = (data) => {
        for (let rule of data.loideveloppe) {
            rule.dimensions = []
            for (let dimension of data.dimension) {
                if (dimension.loi_developpe_id === rule.id) {
                    rule.dimensions.push(dimension)
                }
            }
        }
        return data.loideveloppe
    }

    const manageLengthRules = (data) => {
        for (let rule of data.loi_longueur) {
            rule.pointsSeuils = []
            for (let pointSeuil of data.point_seuil) {
                if (pointSeuil.loiLongueur_id === rule.id) {
                    rule.pointsSeuils.push(pointSeuil)
                }
            }
        }
        return data.loi_longueur
    }

    const manageThicknessRules = (data) => {
        for (let rule of data.loi_epaisseur) {
            rule.pointsSeuils = []
            for (let pointSeuil of data.point_seuil) {
                if (pointSeuil.loiEpaisseur_id === rule.id) {
                    rule.pointsSeuils.push(pointSeuil)
                }
            }
        }
        return data.loi_epaisseur
    }

    const handleGetDevelopedRules = async () => {
        const developedRules = manageDevelopedRules(await getDevelopedRules())
        setDevelopedRules(developedRules)
    }

    const handleGetThicknessRules = async () => {
        const thicknessRules = manageThicknessRules(await getThicknessRules())
        setThicknessRules(thicknessRules)
    }

    const handleGetLengthRules = async () => {
        const lengthRules = manageLengthRules(await getLengthRules())
        setLengthRules(lengthRules)
    }

    const handleDeleteDevelopedRule = async (developedRuleId) => {
        try {
            await deleteDevelopedRule(developedRuleId)
            setTriggerRefetch(!triggerRefetch)
            toast.success("La loi de développé a été supprimée avec succès")
        } catch (err) {
            toast.error("Erreur durant la suppression de la loi de développé")
            console.log(err)
        }
    }

    const handleDeleteThicknessRule = async (thicknessRuleId) => {
        try {
            await deleteThicknessRule(thicknessRuleId)
            setTriggerRefetch(!triggerRefetch)
            toast.success("La loi d'épaisseur a été supprimée avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la suppression de la loi d'épaisseur")
        }
    }

    const handleDeleteLengthRule = async (lengthRuleId) => {
        try {
            await deleteLengthRule(lengthRuleId)
            setTriggerRefetch(!triggerRefetch)
            toast.success("La loi de longueur a été supprimée avec succès")
        } catch (err) {
            console.log(err)
            toast.error("Erreur durant la suppression de la loi de longueur")
        }
    }

    return (
        <>
            <PricingRulesLengthRules data={lengthRules} handleDeleteLengthRule={handleDeleteLengthRule} triggerRefetch={triggerRefetch}
                                        setTriggerRefetch={setTriggerRefetch}/>
            <PricingRulesThicknessRules data={thicknessRules} handleDeleteThicknessRule={handleDeleteThicknessRule} triggerRefetch={triggerRefetch}
                                        setTriggerRefetch={setTriggerRefetch}/>
            <PricingRulesDevelopedRules data={developedRules} handleDeleteDevelopedRule={handleDeleteDevelopedRule} triggerRefetch={triggerRefetch}
                                        setTriggerRefetch={setTriggerRefetch}/>
        </>
    )
}

export default PricingRulesManager
