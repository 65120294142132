import React, {useState, useEffect} from "react"
import {Button, Icon, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@mui/material'
import "./PricingRules.scss"
import PricingRulesNewEditDevelopedRuleModal from "./PricingRulesNewEditDevelopedRuleModal";

const PricingRulesDevelopedRules = ({data, handleDeleteDevelopedRule, triggerRefetch, setTriggerRefetch}) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [selectedRule, setSelectedRule] = useState(null)
    const [modalMode, setModalMode] = useState(null)

    const handlePageChange = (e, value) => {
        setPageNumber(value)
    }

    const categories = [
        {
            title: "Libellé",
            key: "libelle"
        },
        {
            title: "Dimensions",
            key: "sizes"
        },
        {
            title: "Calcul associé",
            key: "associatedCalculation"
        },
        {
            title: "Réprésentation",
            key: "representation"
        },
        {
            title: "Actions",
            key: "actions"
        },
    ]

    const startRange = (pageNumber - 1) * 5
    const endRange = pageNumber * 5

    const handleEditAddRule = (rule) => {
        if(rule){
            setSelectedRule(rule)
            setModalMode("edit")
        } else {
            setSelectedRule({libelle:"", calcul:"", representation:"", dimensions:[]})
            setModalMode("create")
        }
    }

    const handleCloseModal = () => {
        setModalMode(null)
        setSelectedRule(null)
    }


    return (
        data &&
        <Paper elevation={8} className={"pricing-rules-developed-rules-wrapper"}>
            <div className={"pricing-rules-developed-rules-wrapper-header"}>
                <div className={"pricing-rules-developed-rules-wrapper-header-title"}>
                    Lois de calcul de développé
                </div>
                <Button variant={"contained"} onClick={() => handleEditAddRule()}>Ajouter une loi de développé</Button>
            </div>
            <div className={"pricing-rules-developed-rules-wrapper-content"}>
                <TableContainer component={Paper} className={"pricing-rules-developed-rules-wrapper-content-table"}>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                {categories.map((category) => {
                                    return (
                                        <TableCell style={{fontWeight:"bold"}} key={category.key}>{category.title}</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(startRange, endRange).map((rule) => (
                                <TableRow
                                    key={rule.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        {rule.libelle}
                                    </TableCell>
                                    <TableCell>{rule.dimensions.map((dimension, i) => {
                                        return (
                                            <span>
                                                <strong>{dimension.libelle}</strong>
                                                {` (${dimension.unite})${i !== rule.dimensions.length - 1 ? ", " : ""}`}
                                            </span>

                                        )
                                    })}</TableCell>
                                    <TableCell>Calcul associé en attente du back</TableCell>
                                    <TableCell>Réprésentation en attente du back</TableCell>
                                    <TableCell style={{width: "150px"}}>
                                        <IconButton
                                            onClick={() => handleEditAddRule(rule)}
                                            style={{}}>
                                            <Icon color={"primary"}>{"edit"}</Icon>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDeleteDevelopedRule(rule.id)}
                                            style={{}}>
                                            <Icon color={"primary"}>{"delete"}</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className={"pricing-rules-developed-rules-wrapper-content-pagination"}>
                        {/*<Pagination/>*/}
                        <Pagination count={Math.ceil(data.length / 5)} onChange={handlePageChange} page={pageNumber}/>
                    </div>
                </TableContainer>
                {modalMode && <PricingRulesNewEditDevelopedRuleModal rule={selectedRule} mode={modalMode} handleClose={handleCloseModal} triggerRefetch={triggerRefetch}
                                                                        setTriggerRefetch={setTriggerRefetch}/>}
            </div>
        </Paper>
    )
}

export default PricingRulesDevelopedRules